<template>
  <section class="section" id="gallery">
    <v-responsive :aspect-ratio="16 / 9">
      <v-carousel v-model="model" height="100%">
        <v-carousel-item
          v-for="item in galleryImages"
          :key="item.key"
          :src="item.url"
          :lazy-src="item.thumbUrl"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <span class="galleryInfo">
            {{ item.description }}
          </span>
        </v-carousel-item>
      </v-carousel>
    </v-responsive>
  </section>
</template>

<script>
import Settings from "@/settings.js";

export default {
  data() {
    return {
      imageHost: Settings.imageHost,
      model: 0,
      testImg: "img/frontPage.jpg"
      // galleryImages: []
    };
  },
  computed: {
    // imgHostGallery() {
    //   return this.imageHost + "slideshow/1200/";
    // },
    // imgHostLazy() {
    //   return this.imageHost + "slideshow/200/";
    // }
    galleryImages() {
      return this.$store.getters.getImages("slideshow");
    }
  },
};
</script>

<style scoped>
.galleryImage {
  height: 300px;
}
.galleryInfo {
  position: absolute;
  color: white;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
</style>
