import Vue from "vue";
import Vuex from "vuex";
import Settings from "@/settings";
// import { get } from "core-js/core/dict";

Vue.use(Vuex);

// var contains = function (haystack, needle) {
//   return !!~haystack.indexOf(needle);
// };

const content = {
  state: {
    images: [],
    // IMAGES RETURN MODEL:
    // key: str
    // description: Optional[str]
    // slug: str
    // order: Optional[int]
    // file: str
    // thumb: Optional[str]
    // url: str
    // thumbUrl: Optional[str]

    // IMAGE POST MODEL:
    // "imageData": "string",
    // "description": "string",
    // "slug": "string",
    // "order": 0,
    // "name": "string"
    maerkte: [],
    texte: []
  },
  getters: {
    getAllImages: state => {
      return state.images;
    },
    getMarketImage: state => {
      return state.images.find(img => img.slug == "market");
    },
    getTitleImage: state => {
      return state.images.find(img => img.slug == "title");
    },
    getImageBySlug: state => slug => {
      return state.images.find(img => img.slug == slug);
    },
    getImages: state => slug => {
      let theimages = state.images.filter(img => img.slug == slug);
      theimages.sort((a, b) => {
        return a.order - b.order;
      });
      return theimages;
    },
    getSingleImage: state => key => {
      return state.images.find(img => img.key == key);
    }
  },
  mutations: {
    setAllPictures(state, pics) {
      state.images = pics;
    },
    addImage(state, img) {
      state.images.push(img);
    },
    deleteImage(state, key) {
      state.images = state.images.filter(img => img.key != key);
    },
    editImage(state, payload) {
      let index = state.images.indexOf(
        state.pictures.find(pic => pic.key == payload.key)
      );

      if (index != -1) {
        state.images[index] = payload;
      }
    },
    setOrderOfImages(state, newOrder) {
      //newOrder is an array of images sorted in the order we want to save

      let i = 1;

      for (let img of newOrder) {
        // manual rewrite of edit function
        let index = state.images.indexOf(img);
        // console.log('index:' + String(index) + 'img' + img)

        if (index != -1) {
          state.images[index].order = i;
          console.log(state.images[index]);
        }
        // increase counter
        i++;
        // console.log(i)
      }
    }
  },
  actions: {
    // Pictures
    async getPictures({ commit, dispatch }) {
      let allPictures = await dispatch(
        "apiCall",
        {
          url: "pics/",
          method: "GET"
        },
        { root: true }
      );
      commit("setAllPictures", allPictures);
    },
    async newPicture({ commit, dispatch }, { pictureData }) {
      var newPic = null;
      console.log("NEWPICTURE ACTION");
      console.log(pictureData);

      newPic = await dispatch(
        "apiCall",
        {
          url: "pics/",
          method: "POST",
          objectData: pictureData
        },
        { root: true }
      );

      if (newPic) {
        commit("addImage", newPic);
        commit(
          "goodNews",
          {
            message: "Created new picture " + newPic.title,
            timeout: 3000
          },
          { root: true }
        );
      }

      return newPic;
    },
    async editPicture({ commit, dispatch }, { key, picData }) {
      var newPicData = null;
      console.log(picData);
      newPicData = await dispatch(
        "apiCall",
        {
          url: "pics/" + key,
          method: "PUT",
          objectData: picData
        },
        { root: true }
      );

      if (newPicData) {
        commit("editPicture", newPicData);
        commit(
          "goodNews",
          {
            message: "Edited picture " + newPicData.title,
            timeout: 3000
          },
          { root: true }
        );
      } else {
        console.log("FAILED EDIT IMAGE");
        console.log(newPicData);
      }
      return newPicData;
    },
    async deletePicture({ commit, dispatch }, { picKey }) {
      console.log("key to delete: " + picKey);
      let deletion = await dispatch(
        "apiCall",
        {
          url: "pics/" + picKey,
          method: "DELETE"
        },
        { root: true }
      );
      if (deletion) {
        console.log("deleted " + picKey);
        commit("deleteImage", picKey);
        commit(
          "goodNews",
          {
            message: "Deleted picture ",
            timeout: 3000
          },
          { root: true }
        );
        return true;
      }
    },
    async saveNewPictureOrder({ getters, dispatch }, { slug }) {
      // generating an array of key: order pairs
      // let newOrderArray = {}

      // backend doesn't want to serve the sleek array-only approach...
      // for (let img of getters.getImages(slug)) {
      //     newOrderArray[img.key] = img.order
      // }
      // let newOrder = await dispatch(
      //     'apiCall',
      //     {
      //     url: 'pics/reorder',
      //     method: 'POST',
      //     objectData: newOrderArray,
      //     },
      //     {root: true}
      // );
      // return newOrder

      // alternative, heavy approach:
      let allpics = getters.getImages(slug);
      for (let img of allpics) {
        let imgData = {
          imageData: "", //imageData and name can be empty if not new image is uploaded
          description: img.description,
          slug: img.slug,
          order: img.order,
          name: ""
        };
        await dispatch("apiCall", {
          url: "pics/" + String(img.key),
          method: "PUT",
          objectData: imgData
        });
      }
    }
  }
};

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    // {
    //   "id": 1,
    //   "username": "admin",
    //   "email": null,
    //   "full_name": null,
    //   "disabled": null,
    //   "scopes": "admin"
    // }
    errors: [],
    // {
    //   msg: String,
    //   compnent: string - component name, default ''
    // }
    snack: null,
    // {
    //   message: string,
    //   timeout: Int,
    // }
    loading: false // bool
  },
  getters: {
    getUser: state => {
      // returns the user object as set from backend
      return state.user;
    },
    loggedIn: state => {
      // returns true or false
      let deltaT = 30 * 60 * 1000; // time until logout
      // console.log('login time data')
      // console.log(Date.now() - deltaT)
      // console.log(localStorage.getItem("loginTime") )
      if (state.status === "success") {
        return true;
      } else if (
        // check if we have localStorage auth
        localStorage.getItem("token_type") &&
        localStorage.getItem("access_token") &&
        localStorage.getItem("loginTime") < Date.now() - deltaT
      ) {
        return true;
      } else {
        return false;
      }
    },
    getAllErrors: state => {
      // returns an array of all errors
      // used to display error messages in App.vue
      return state.errors;
    },
    allTheGoodNews: state => {
      return state.snack;
    },
    isLoading: state => {
      return state.loading;
    }
  },
  mutations: {
    auth_request(state) {
      // setting the login status during the login process
      state.status = "loading";
    },
    auth_success(state, payload) {
      // used in login process
      state.status = "success";
      state.token = payload.token;
      state.user = payload.user;
    },
    auth_error(state) {
      // used in login process
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("logintTime");
    },
    registerError(state, payload) {
      // registers a new error
      // payload either string as error message or object, see states
      var error = {};
      if (typeof payload === String) {
        error = {
          msg: payload,
          component: ""
        };
      } else {
        error = {
          msg: payload.message,
          component: payload.component
        };
      }
      state.errors.push(error);
    },
    removeError(state, index) {
      // removes errors by array index; used in ErrorMessage.vue to dismiss not only the error but remove it
      state.errors.splice(index, 1);
    },
    clearErrors(state, componentName = "") {
      // use with caution when component = ''
      // otherwise to clear all errors of a component, e.g. 'LoginProcess'
      state.errors = state.errors.filter(e => !(e.component === componentName));
    },
    goodNews(state, payLoad) {
      let news = null;
      if (payLoad.timeout && payLoad.message) {
        news = payLoad;
      } else if (payLoad.message) {
        news = {
          message: payLoad.message,
          timeout: 3000
        };
      } else {
        news = {
          message: payLoad,
          timeout: 3000
        };
      }
      state.snack = news;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    }
  },
  actions: {
    async login({ commit }, user) {
      // the login process
      commit("startLoading"); // loading
      commit("auth_request"); // login
      commit("clearErrors", "loginProcess");
      // create form body for oauth2 login scheme
      var formBody = [];
      for (var property in user) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(user[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      try {
        // commit to backend:
        const response = await fetch(Settings.backend + "login", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
          },
          body: formBody
        });

        const status = response.status;
        const data = await response.json();

        // in case of success:
        if (status === 200) {
          // success!
          let token = {
            access_token: data.access_token,
            token_type: data.token_type
          };
          localStorage.setItem("access_token", token["access_token"]);
          localStorage.setItem("token_type", token["token_type"]);
          localStorage.setItem("loginTime", Date.now());

          // user = getters.getUser

          const userRequest = await fetch(Settings.backend + "profile/me", {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization:
                localStorage.token_type + " " + localStorage.access_token
            }
          });
          const userFromDB = await userRequest.json();
          console.log(userFromDB);

          commit("auth_success", { token: token, user: userFromDB });
          commit("stopLoading");

          return true;
        } else if (status === 400) {
          commit("auth_error");
          commit("registerError", {
            message: "Could not log in; wrong username or password",
            component: "loginProcess"
          });
          // throw "Could not log in; wrong username or password";
        } else {
          console.error(status, data);
          commit("auth_error");
          commit("registerError", {
            message: "error " + status + " please try again later",
            component: "loginProcess"
          });
          // throw "error " + status + " please try again later";
        }
      } catch (error) {
        console.error(error);
        commit("auth_error");
        commit("registerError", {
          message: "server error while logging in; please try again later",
          component: "loginProcess"
        });
        // throw "server error; please try again later";
      }
      commit("stopLoading");
    },
    async apiCall(
      { commit, getters },
      { url, method = "GET", objectData = null }
    ) {
      // generalised apiCall to the backend, can be used for GET/POST/PUT/DELETE
      commit("startLoading"); // loading icon

      console.log(url);
      console.log(method);
      console.log(objectData);

      try {
        let messageData = {};
        let messageHeader = {};
        if (getters.loggedIn) {
          // in case of logged in we want to add authorisation
          // doesn't hurt if it is not there...
          console.log("logged in, adding auth");
          messageHeader = Object.assign(messageHeader, {
            Authorization:
              localStorage.token_type + " " + localStorage.access_token
          });
        }
        if (method != "GET") {
          // If we have another method
          messageData = Object.assign(messageData, {
            method: method
          });
        }

        if (objectData && method != "GET") {
          // need to add content to header
          messageHeader = Object.assign(messageHeader, {
            "Content-Type": "application/json"
          });
          // need to attach body
          messageData = Object.assign(messageData, {
            body: JSON.stringify(objectData)
          });
        }

        if (messageHeader != {}) {
          // add header if not empty
          messageData = Object.assign(messageData, {
            headers: messageHeader
          });
        }

        console.log(messageData);
        console.log(Settings.backend);
        console.log(url);

        const response = await fetch(
          String(Settings.backend + url),
          messageData
        );

        //const data = await response.json()
        if (response.status === 200) {
          //console.log(response.json())
          let returnValue = response.json();
          // console.log(returnValue)
          if (!returnValue) {
            // in case of e.g. deletion where there is only 200 as response
            returnValue = true;
          }
          commit("stopLoading");
          return returnValue;
        } else if (response.status === 401) {
          // commit("logout");
          commit("registerError", {
            message:
              "You are not logged in or don't have permission for this function",
            component: "APIcall"
          });
        } else {
          commit("registerError", {
            message:
              "Error " +
              response.status +
              ' Could not perform API call for: url="' +
              url +
              '", method="' +
              method +
              '", data="' +
              JSON.stringify(objectData) +
              '".',
            component: "APIcall"
          });
        }
      } catch (error) {
        console.error(error);
        commit("registerError", {
          message: "APIcall produced an error: " + error,
          component: "APIcall"
        });
      }
      commit("stopLoading");
    }
  },
  modules: {
    content: content
  }
});
