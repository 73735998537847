<template>
  <div>
    <section class="section" id="header">
      <v-parallax :src="imageHost + 'start4.jpg'" height="700">
        <v-container fill-height>
          <v-img
            :src="imageHost + 'logo4.png'"
            id="logo"
            alt="Porzellan freigedreht"
            contain
            max-height="60%"
          />
        </v-container>
      </v-parallax>
    </section>

    <TextBlock textSlug="about" />
    <MarketOverview />
    <TextBlock textSlug="info" />
    <TheGallery />
    <!-- <TextBlock :textSlug="'ProduktGallerie'" /> -->
    <ProductGallery />
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import MarketOverview from "@/views/MarketOverview";
import TheGallery from "@/views/TheGallery";
import TextBlock from "@/views/textBlock";
import ProductGallery from "@/views/ProductGallery.vue";
import Settings from "@/settings.js";
import utils from "@/utils.js";

export default {
  name: "Home",

  components: {
    TextBlock,
    MarketOverview,
    TheGallery,
    ProductGallery,
  },

  data() {
    return {
      imageHost: Settings.imageHost,
      frontPageImg: {},
      sectionObserver: null // needed for scroll-update-URL
    };
  },
  props: {},
  methods: {
    loadFrontPageImage() {
      this.frontPageImg = utils.loadImage("frontPage");
    },

    // changing URL on scroll
    // observeSections() {
    //   try {
    //     this.sectionObserver.disconnect();
    //   } catch (error) {
    //     console.log("something wrong in sectino observer");
    //   }

    //   const options = {
    //     rootMargin: "0px 0px",
    //     threshold: 0
    //   };
    //   this.sectionObserver = new IntersectionObserver(
    //     this.sectionObserverHandler,
    //     options
    //   );

    //   // Observe each section
    //   const sections = document.querySelectorAll(".section");
    //   sections.forEach(section => {
    //     this.sectionObserver.observe(section);
    //     console.log("sectin: " + section.id);
    //   });
    // },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          this.$router.push({ name: this.$route.name, hash: `#${sectionId}` });
        }
      }
    }
  },
  mounted() {
    this.frontPageImg = utils.loadImage("frontPage");
    this.observeSections(); // needed for scroll-update-URL
  }
};
</script>

<style scoped>
.v-parallax__imagecontainer img {
  width: 120%;
}
</style>

data () { return { } }, mounted () { }, methods: { }
