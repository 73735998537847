// Dummydata for build only
import dummyData from "@/dummyData";
import Settings from "@/settings";
// import router from "./router";
// import logout from "./router"
//import moment from 'moment'
import { logout, isLoggedIn } from "@/router";

async function apiCall(url, method = "GET", objectData = null) {
  // treeData:
  // { name: 'string', description: 'string}
  try {
    let messageData = {};
    if (method != "GET") {
      messageData = Object.assign(messageData, {
        method: method,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization:
            localStorage.token_type + " " + localStorage.access_token
        }
      });
    } else if (isLoggedIn()) {
      messageData = Object.assign(messageData, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization:
            localStorage.token_type + " " + localStorage.access_token
        }
      });
    }
    if (objectData && method != "GET") {
      messageData = Object.assign(messageData, {
        body: JSON.stringify(objectData)
      });
    }

    // console.log(messageData);

    const response = await fetch(Settings.backend + url, messageData);

    //const data = await response.json()
    if (response.status === 200) {
      //console.log(response.json())
      let returnValue = response.json();
      // console.log(returnValue)
      return returnValue;
    } else if (response.status === 401) {
      logout();
      throw "You are not logged in or don't have permission for this function";
    } else {
      throw "Error " +
        response.status +
        ' Could not perform API call for: url="' +
        url +
        '", method="' +
        method +
        '", data="' +
        JSON.stringify(objectData) +
        '".';
    }
  } catch (error) {
    console.error(error);
    throw "Could not perform API call: " + error;
  }
}

export default {
  loadImage(slug) {
    // console.log('getting img: ' + slug)
    // console.log( dummyData.images.find(img => img.slug===slug))
    return dummyData.images.find(img => img.slug === slug);
  },

  async loadMarkets() {
    // console.log(dummyData.markets)
    // return dummyData.markets
    let markets = await apiCall("markets/");

    // console.log(markets)

    return markets;
    // try {
    //   const response = await fetch(Settings.backend + "markets/");
    //   const data = await response.json();
    //   return data;
    // } catch (error) {
    //   console.error(error);
    //   throw "Märkte konnten nicht geladen werden... ";
    // }
  },

  loadSeveralImages(slug) {
    return dummyData.images.filter(img => img.slug === slug);
  },

  async loadTextBlock(textSlug) {
    try {
      const response = await fetch(Settings.backend + "textblock/" + textSlug);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw "Der Text zum Thema " + textSlug + " konnte nicht geladen werden.";
    }

    // let textBlock = {
    //     title: 'Error loading text block',
    //     text: 'Leider konnte dieses Textelement nicht geladen werden. Sollte dieser Fehler bestehen bleiben, wenden Sie sich bitte an den Webmaster.',
    //     slug: textSlug
    // }
    // console.log('fetching textblock: '+textSlug)
    // try {
    //     const response = await fetch(Settings.backend + 'textblock/' + textSlug) //https://server/api/textblock/about
    //     const data = await response.json()
    //     console.log(data.title)
    //     textBlock.title = data.title
    //     textBlock.text = data.content
    //     console.log('data: ' + data.title + '; ' + data.content)
    //     console.log('tb: ' + textBlock.title + '; ' + textBlock.text)

    //     // Dummy data import:
    //     // return dummyData.texts.find(text => text.slug === textSlug)

    // } catch (error) {
    //   console.error(error)
    // }
    // return textBlock
  },

  async saveTextBlock(textBlock) {
    // textBlock:
    // slug: string (primary key)
    // title: string
    // text: string
    const textToSubmit = {
      content: textBlock.content,
      title: textBlock.title,
      quote: textBlock.quote
    };
    return await apiCall("textblock/" + textBlock.slug, "PUT", textToSubmit);
  },

  saveImageEdit(img) {
    return img;
  },

  async saveMarketEdit(market) {
    let marketObject = {
      market_name: market.market_name,
      start_date: market.start_date,
      infotext: market.infotext,
      link: market.link,
      end_date: market.end_date
    };

    const response = apiCall("markets/" + market.key, "PUT", marketObject);
    // await fetch(Settings.backend + 'market/' + market.id, {
    //   method: 'PUT',
    //   body: JSON.stringify(marketObject),
    //   headers: { 'Content-type': 'application/json; charset=UTF-8' },
    // })
    //const data = await response.json()
    // if (response.status === 200) {
    //     returnObject.status = response.status
    //     returnObject.data = response.json()
    //     returnObject.errors = null
    //     console.log('great success 200')
    // } else if (response.status === 401) {
    //     logout('You need to login for this function.')
    // } else {
    //     returnObject.status = response.status
    //     returnObject.errors = response.json()
    // }

    // return returnObject
    return response;
  },

  async saveNewMarket(market) {
    // market structure:
    // {
    //     id: 0,
    //     name: "",
    //     start_date: "",
    //     end_date: "",
    //     infotext: "",
    //     link: "",
    //     }
    let marketObject = {
      market_name: market.market_name,
      start_date: String(market.start_date),
      infotext: market.infotext,
      link: market.link,
      end_date: String(market.end_date)
    };
    return apiCall("markets/", "POST", marketObject);
  },

  async deleteMarket(marketID) {
    // console.log("deleting market #" + marketID);
    let returnObj = apiCall("markets/" + marketID, "DELETE");
    return returnObj;
  },

  async saveNewImage(imgData) {
    console.log(imgData);
    let response = await apiCall("pics/", "POST", imgData);

    return response;
  },

  async loadImagesDB(slug = "") {
    let response = await apiCall("pics/" + String(slug));
    return response;
  },

  async deleteImage(picID) {
    let response = await apiCall("/pics/" + String(picID), "DELETE");
    return response;
  },

  async editImage(picID, picData) {
    let response = await apiCall("/pics/" + String(picID), "PUT", picData);
    return response;
  },

  async loginToBackend(username, password) {
    // const formdata = new FormData()
    // formdata.append('username', username)
    // formdata.append('password', password)
    // console.log(user)
    // const { username, password } = this

    let user = { username: username, password: password };

    var formBody = [];
    for (var property in user) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(user[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try {
      const response = await fetch(Settings.backend + "login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
      });

      const status = response.status;
      const data = await response.json();

      if (status === 200) {
        // success!
        let token = {
          access_token: data.access_token,
          token_type: data.token_type
        };
        localStorage.setItem("access_token", token["access_token"]);
        localStorage.setItem("token_type", token["token_type"]);
        localStorage.setItem("loginTime", Date.now());
        return "logged in";
      } else if (status === 401) {
        return "wrong user";
      } else {
        console.error(status, data);
        return "unknown error; please try again later";
      }
    } catch (error) {
      console.error(error);
      return "server error; please try again later";
    }
  },

  async loadProfile() {
    return apiCall("profile/me");
  },
  async saveNewPassword(pw) {
    // let data={'password': pw}
    return apiCall("profiles/", "PUT", pw);
  }
};
